import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _77002223 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _14c2fba7 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _74707e0a = () => interopDefault(import('../pages/travel-alerts.vue' /* webpackChunkName: "pages/travel-alerts" */))
const _bd14b4b0 = () => interopDefault(import('../pages/visa-entry-requirements.vue' /* webpackChunkName: "pages/visa-entry-requirements" */))
const _58ddbac7 = () => interopDefault(import('../pages/wildlandtrekking-registration.vue' /* webpackChunkName: "pages/wildlandtrekking-registration" */))
const _161cb784 = () => interopDefault(import('../pages/wishlist.vue' /* webpackChunkName: "pages/wishlist" */))
const _53df68cd = () => interopDefault(import('../pages/auth0/loggedin.vue' /* webpackChunkName: "pages/auth0/loggedin" */))
const _0ac0f630 = () => interopDefault(import('../pages/auth0/logout.vue' /* webpackChunkName: "pages/auth0/logout" */))
const _a3c3ba08 = () => interopDefault(import('../pages/booking/agent-login.vue' /* webpackChunkName: "pages/booking/agent-login" */))
const _0f968f8c = () => interopDefault(import('../pages/booking/feedback.vue' /* webpackChunkName: "pages/booking/feedback" */))
const _3cc160e6 = () => interopDefault(import('../pages/booking/manage-booking.vue' /* webpackChunkName: "pages/booking/manage-booking" */))
const _2bb626f6 = () => interopDefault(import('../pages/preview/home.vue' /* webpackChunkName: "pages/preview/home" */))
const _3640fd74 = () => interopDefault(import('../pages/preview/travel-alerts.vue' /* webpackChunkName: "pages/preview/travel-alerts" */))
const _ae7eccae = () => interopDefault(import('../pages/preview/_.vue' /* webpackChunkName: "pages/preview/_" */))
const _e039a0a0 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___au"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___be"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___ca"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___ch"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___de"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___en"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___eu"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___ie"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___mt"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___nl"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___nz"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___uk"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___us"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?",
    component: _77002223,
    name: "index___za"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___au"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___au"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___au"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___au"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___au"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___be"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___be"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___be"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___be"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___be"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___ca"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___ca"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___ca"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___ca"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___ca"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___ch"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___ch"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___ch"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___ch"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___ch"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___de"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___de"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___de"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___de"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___de"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___en"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___en"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___en"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___en"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___en"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___eu"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___eu"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___eu"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___eu"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___eu"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___ie"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___ie"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___ie"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___ie"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___ie"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___mt"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___mt"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___mt"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___mt"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___mt"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___nl"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___nl"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___nl"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___nl"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___nl"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___nz"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___nz"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___nz"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___nz"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___nz"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___uk"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___uk"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___uk"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___uk"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___uk"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___us"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___us"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___us"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___us"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___us"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/search",
    component: _14c2fba7,
    name: "search___za"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/travel-alerts",
    component: _74707e0a,
    name: "travel-alerts___za"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/visa-entry-requirements",
    component: _bd14b4b0,
    name: "visa-entry-requirements___za"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wildlandtrekking-registration",
    component: _58ddbac7,
    name: "wildlandtrekking-registration___za"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/wishlist",
    component: _161cb784,
    name: "wishlist___za"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___au"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___au"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___au"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___au"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___au"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___au"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___au"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___be"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___be"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___be"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___be"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___be"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___be"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___be"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___ca"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___ca"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___ca"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___ca"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___ca"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___ca"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___ca"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___ch"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___ch"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___ch"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___ch"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___ch"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___ch"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___ch"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___de"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___de"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___de"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___de"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___de"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___de"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___de"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___en"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___en"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___en"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___en"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___en"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___en"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___en"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___eu"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___eu"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___eu"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___eu"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___eu"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___eu"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___eu"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___ie"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___ie"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___ie"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___ie"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___ie"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___ie"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___ie"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___mt"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___mt"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___mt"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___mt"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___mt"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___mt"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___mt"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___nl"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___nl"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___nl"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___nl"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___nl"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___nl"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___nl"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___nz"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___nz"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___nz"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___nz"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___nz"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___nz"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___nz"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___uk"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___uk"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___uk"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___uk"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___uk"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___uk"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___uk"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___us"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___us"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___us"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___us"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___us"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___us"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___us"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/loggedin",
    component: _53df68cd,
    name: "auth0-loggedin___za"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/auth0/logout",
    component: _0ac0f630,
    name: "auth0-logout___za"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/agent-login",
    component: _a3c3ba08,
    name: "booking-agent-login___za"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/feedback",
    component: _0f968f8c,
    name: "booking-feedback___za"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/booking/manage-booking",
    component: _3cc160e6,
    name: "booking-manage-booking___za"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/home",
    component: _2bb626f6,
    name: "preview-home___za"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/travel-alerts",
    component: _3640fd74,
    name: "preview-travel-alerts___za"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___de"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___mt"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___eu"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___nl"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___be"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___za"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___ch"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___us"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___uk"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___nz"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___ie"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___ca"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___au"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/preview/*",
    component: _ae7eccae,
    name: "preview-all___en"
  }, {
    path: "/de/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___de"
  }, {
    path: "/mt/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___mt"
  }, {
    path: "/eu/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___eu"
  }, {
    path: "/nl/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___nl"
  }, {
    path: "/be/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___be"
  }, {
    path: "/za/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___za"
  }, {
    path: "/ch/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___ch"
  }, {
    path: "/us/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___us"
  }, {
    path: "/uk/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___uk"
  }, {
    path: "/nz/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___nz"
  }, {
    path: "/ie/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___ie"
  }, {
    path: "/ca/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___ca"
  }, {
    path: "/au/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___au"
  }, {
    path: "/en/:currencyCode(aud|cad|chf|eur|gbp|nzd|usd|zar)?/*",
    component: _e039a0a0,
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
